import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {SelectTableModalComponent} from '../select-table-modal/select-table-modal.component';

@Component({
	selector: 'app-app-toolbar-logo',
	templateUrl: './app-toolbar-logo.component.html',
	styleUrls: ['./app-toolbar-logo.component.scss']
})
export class AppToolbarLogoComponent implements OnInit {
	@Input() order: Order;
	@Input() venue: Venue;
	@Output() close = new EventEmitter<void>();
	constructor(private modalCtrl: ModalController, private repository: RepositoryService) {}

	ngOnInit() {}
	exit() {
		this.close.emit();
	}
	async openSelectTableModal() {
		const result = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
		if (result?.table && result?.tableId) {
			this.order.tableNumber = result.table;
			this.order.table = result.tableId;
			this.repository.order.emit(this.order);
		}
	}
}
