import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Api} from 'src/smoothr-web-app-core/api/api';
import Order from 'src/smoothr-web-app-core/models/Order';
import Table from 'src/smoothr-web-app-core/models/Table';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

enum Language {
	GreatBritain = 'en',
	German = 'de',
	Italian = 'it',
	Spanish = 'es',
	France = 'fr'
}

@Component({
	selector: 'app-select-table-modal',
	templateUrl: './select-table-modal.component.html',
	styleUrls: ['./select-table-modal.component.scss']
})
export class SelectTableModalComponent implements OnInit {
	languageEnum = Language;
	selectedLanguage = this.translate.getDefaultLang();
	venue: Venue;
	order: Order;
	selectedTable = null;
	tableNumber = '';
	tableError = null;
	loading = false;
	tablesArray: Table[] = [];
	constructor(
		private modalCtrl: ModalController,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService
	) {}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		order: Order
	): Promise<{
		table: string;
		tableId: string;
	}> {
		const modal = await modalCtrl.create({
			component: SelectTableModalComponent,
			componentProps: {
				venue,
				order
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		return (await modal.onDidDismiss()).data;
	}

	ngOnInit() {
		this.getTables();
	}

	async goBack() {
		await this.modalCtrl.dismiss();
	}

	async close() {
		await this.modalCtrl.dismiss({
			table: this.selectedTable.number,
			tableId: this.selectedTable._id
		});
	}

	async getTables() {
		try {
			this.loading = true;
			const result = await Api.getTablesByVenue(this.venue._id);
			if (result.data) {
				this.tablesArray = result.data
					.filter(it => it.number !== 'preorder_table' && it.number !== 'system_table')
					.sort((a, b) => Number(a?.number) - Number(b?.number));
				if (this.order.table && this.order.tableNumber) {
					if (this.order.tableNumber !== 'preorder_table') {
						this.selectedTable = this.tablesArray.find(it => it._id === this.order.table);
					}
				}
			}
			this.loading = false;
		} catch (e) {
			console.log(e);
			this.loading = false;

			this.snackbarCtrl.open(this.translate.instant('select_table.error'), null, {duration: 5000});
		}
	}
	selectTable(table: Table) {
		this.selectedTable = table;
	}
	changeLanguage() {
		// this.selectedLanguage = lang;
		this.translate.setDefaultLang(this.selectedLanguage);
		this.translate.use(this.selectedLanguage);
	}
	selectedImage() {
		return '/assets/terrace/flag_' + this.selectedLanguage + '.png';
	}
}
