import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {NavigationService} from 'src/app/services/navigation.service';
import {ScanQrModal} from 'src/app/components/scan-qr/scan-qr.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ActivatedRoute} from '@angular/router';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {BehaviorSubject} from 'rxjs';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';
import moment from 'moment';
import { ModalTableInfoComponent } from 'src/app/components/modal-table-info/modal-table-info.component';

enum Language {
	GreatBritain = 'en',
	German = 'de',
	Italian = 'it',
	Spanish = 'es',
	France = 'fr'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.tabilo.html',
	styleUrls: ['./home.page.tabilo.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanQr = 'scan-qr/:venueId/:tableId';
	environment = environment;
	languageEnum = Language;
	selectedLanguage = Language.German;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	loading$ = new BehaviorSubject(false);
	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading$.next(true);
		this.route.paramMap.subscribe(params => {
			if (params && params.get('venueId') && params.get('tableId')) {
				// this.loadVenueByQrCode(params.get('venueId'), params.get('tableId'));
			} else {
				this.loadVenues();
			}
		});
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading$.next(true);
		const venue = await this.repository.getAllVenues();
		if (!venue[0].isServiceActivated) {
			this.snackbarCtrl.open(this.translate.instant('home_page.service_not_active'), null, {
				duration: 4000
			});
		}
		const isOpenResturant = TimeUtils.doesHoursMatchNow(venue[0]?.openingHours);
		if (isOpenResturant) {
			const foundedVenue = venue.find(ven => venueAcceptsOrders(ven, preorderType));
			if (!foundedVenue) {
				console.log('No available venues');
				this.loading$.next(false);
				return;
			}
			await this.loadVenueAndCreateOrder(foundedVenue, null, preorderType);
		} else {
			this.loading$.next(false);
			this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
				duration: 5000
			});
		}
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, OrderType.PREORDER, preorderType);
		await this.navigationService.menu();
		this.loading$.next(false);
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => this.loading$.next(false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang() {
		this.translate.setDefaultLang(this.selectedLanguage);
		this.translate.use(this.selectedLanguage);
	}

	async goToSelectVenuePage() {
		await this.navigationService.selectVenue();
	}
	selectedImage() {
		return '../../../assets/terrace/flag_' + this.selectedLanguage + '.png';
	}

	// newModal() {
	// 	ModalTableInfoComponent.show(this.modalCtrl, 12)
	// }
}
