import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const sportsbarCustomerGroup = 'sportsbar';
export const customerGroup = sportsbarCustomerGroup;

const supportEmail = {
	sportsbar: 'sportsbar@smoothr.de'
};
const firebaseConfig = {
	sportsbar: {
		apiKey: 'AIzaSyC0vWJOdHYV-saievI0asBnTl0lCaQosl4',
		authDomain: 'sportsbar-web-app.firebaseapp.com',
		projectId: 'sportsbar-web-app',
		storageBucket: 'sportsbar-web-app.appspot.com',
		messagingSenderId: '538666826714',
		appId: '1:538666826714:web:fc41e394ef78b8bffc9ee3',
		measurementId: 'G-XTJ7MEY1EH'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDQM1zI3EKl0lfnpmaZy4ofMI739NkuASo',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};
