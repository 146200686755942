import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Hours from '../../../smoothr-web-app-core/models/Hours';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import {environment} from 'src/environments/environment';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {ShortInfoModalComponent} from '../short-info-modal/short-info-modal.component';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {ShortInfoHtmlComponent} from '../short-info-html/short-info-html.component';

@Component({
	selector: 'app-working-hours-modal',
	templateUrl: './working-hours-modal.component.html',
	styleUrls: ['working-hours-modal.component.scss']
})
export class WorkingHoursModalComponent {
	venue: Venue;
	type: PreorderType;
	pt = PreorderType;
	environment = environment;
	numberToCurrency = numberToCurrency;
	// tslint:disable-next-line:variable-name
	private _longDays = [
		this.translate.instant('weekday.monday'),
		this.translate.instant('weekday.tuesday'),
		this.translate.instant('weekday.wednesday'),
		this.translate.instant('weekday.thursday'),
		this.translate.instant('weekday.friday'),
		this.translate.instant('weekday.saturday'),
		this.translate.instant('weekday.sunday')
	];

	constructor(private modalCtrl: ModalController, private translate: TranslateService) {}

	// tslint:disable-next-line:variable-name
	private _days = [
		this.translate.instant('weekday.monday_short'),
		this.translate.instant('weekday.tuesday_short'),
		this.translate.instant('weekday.wednesday_short'),
		this.translate.instant('weekday.thursday_short'),
		this.translate.instant('weekday.friday_short'),
		this.translate.instant('weekday.saturday_short'),
		this.translate.instant('weekday.sunday_short')
	];

	get days() {
		return AppComponent.largeScreen ? this._longDays : this._days;
	}

	static async show(modalCtrl: ModalController, venue: Venue, type: PreorderType) {
		try {
			venue.legal = (await Api.getLegal(venue._id)).data;
			console.log({
				venue: venue.name,
				legal: venue.legal,
				id: venue._id
			});
		} catch (e) {
			console.error('Error while getting legal information');
			console.error(e);
		}
		const modal = await modalCtrl.create({
			component: WorkingHoursModalComponent,
			componentProps: {
				venue,
				type
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	async openHtml(html: string) {
		//TODO CHANGES LATER
		await ShortInfoHtmlComponent.show(this.modalCtrl, {
			de: html
		});
	}

	dayText(schedule: Hours[], index: number): string[] {
		const res = [];
		const allConnected = schedule.map(hour => hour.connectedWithPrev).indexOf(false) < 0;
		for (const hour of schedule) {
			if (hour.connectedWithPrev && !allConnected) {
				continue;
			}
			if (hour.weekday === index) {
				const hours = TimeUtils.hoursToSchedule(hour);
				res.push(
					hours.openedAt.format('HH:mm') +
						' - ' +
						hours.closedAt.format('HH:mm') +
						` ${this.translate.instant('working_hours_modal.hours')}`
				);
			}
		}
		if (res.length !== 0) {
			return res;
		}
		return [this.translate.instant('venue_suggestion.close')];
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
