import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {timer} from 'rxjs';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-modal-table-info',
	templateUrl: './modal-table-info.component.html',
	styleUrls: ['./modal-table-info.component.scss']
})
export class ModalTableInfoComponent implements OnInit {
	public tableNumber: string;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, tableNumber: string) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: ModalTableInfoComponent,
			componentProps: {tableNumber},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit(): void {
		timer(3000).subscribe(() => this.modalCtrl.dismiss());
	}
}
