import {Component, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {NavigationService, NAVIGATIONURLS} from 'src/app/services/navigation.service';
import {ArticleCategoryType} from 'src/smoothr-web-app-core/enums/ArticleCategoryType';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
@Component({
	selector: 'app-select-category',
	templateUrl: './select-category.component.html',
	styleUrls: ['./select-category.component.scss']
})
export class SelectCategoryComponent {
	venue: Venue;
	order: Order;
	articleCategoryType = ArticleCategoryType;
	showCategories: ArticleCategory[] = [];
	constructor(private navigationService: NavigationService, private modalCtrl: ModalController) {}
	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false
	};
	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		order: Order
	): Promise<{
		mainCategory: string;
	}> {
		const modal = await modalCtrl.create({
			component: SelectCategoryComponent,
			componentProps: {
				venue,
				order
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		return (await modal.onDidDismiss()).data;
	}

	arrayOfMainCategories(): {dish?: ArticleCategory[]; beverage?: ArticleCategory[]} {
		return this.groupByKey(
			this.venue.articleCategories.filter(it => it.visible && !it.hidden),
			'mainCategory'
		) as {
			dish?: ArticleCategory[];
			beverage?: ArticleCategory[];
		};
	}
	groupByKey(list: ArticleCategory[], key: string) {
		return list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {});
	}
	async selectMainCategory(value: ArticleCategoryType) {
		if (value === ArticleCategoryType.DISH) {
			this.showCategories = this.arrayOfMainCategories()?.dish;
		} else {
			this.showCategories = this.arrayOfMainCategories()?.beverage;
		}
		await this.slides.update();
		await this.slides.slideNext();
	}
	async goBack() {
		await this.slides.slideTo(0);
		this.showCategories = [];
	}
	selectCategory(category: ArticleCategory) {
		this.modalCtrl.dismiss({mainCategory: category._id});
	}
}
